import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home/HomeView.vue"),
  },
  {
    path: "/q_kindergarten",
    name: "kindergarten",
    component: () => import("../views/Kindergarten/KindergartenView.vue"),
    meta: { title: "幼稚園ステージ｜青学からの挑戦状 150クイズの盾" },
  },
  {
    path: "/q_elementary",
    name: "elementary",
    component: () => import("../views/Elementary/ElementaryView.vue"),
    meta: { title: "初等部ステージ｜青学からの挑戦状 150クイズの盾" },
  },
  {
    path: "/q_junior",
    name: "junior",
    component: () => import("../views/Junior/JuniorView.vue"),
    meta: { title: "中等部ステージ｜青学からの挑戦状 150クイズの盾" },
  },
  {
    path: "/q_senior",
    name: "senior",
    component: () => import("../views/Senior/SeniorView.vue"),
    meta: { title: "高等部ステージ｜青学からの挑戦状 150クイズの盾" },
  },
  {
    path: "/q_university",
    name: "university",
    component: () => import("../views/University/UniversityView.vue"),
    meta: { title: "大学（青山）ステージ｜青学からの挑戦状 150クイズの盾" },
  },
  {
    path: "/q_sagamihara",
    name: "sagamihara",
    component: () => import("../views/Sagamihara/SagamiharaView.vue"),
    meta: { title: "大学（相模原）ステージ｜青学からの挑戦状 150クイズの盾" },
  },
  {
    path: "/q_athletics",
    name: "athletics",
    component: () => import("../views/Athletics/AthleticsView.vue"),
    meta: { title: "陸上競技部ステージ｜青学からの挑戦状 150クイズの盾" },
  },
  {
    path: "/q_mashima",
    name: "mashima",
    component: () => import("../views/Mashima/MashimaView.vue"),
    meta: { title: "最終問題｜青学からの挑戦状 150クイズの盾" },
  },
];
const DEFAULT_TITLE = "青学からの挑戦状 150クイズの盾";
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
